import Statsig from 'statsig-js';

import { usePreferredLanguages } from '@vueuse/core';

const browserLanguages = usePreferredLanguages();

function logEvent(
    eventName: string,
    value?: string | number | null,
    metadata?: Record<string, string> | null
): void {
    Statsig.logEvent(eventName, value, {
        ...metadata,
        // I'd probably like to add some interesting metadata here
        browser_preferred_languages: browserLanguages.value[0],
    });
}

export default function useMetrics() {
    return {
        //
        logEvent,
    };
}
